<template>
  <div class="hl-full-container flexVertical">

    <!-- 表单e -->
    <div class="search">
      <el-form :model="form" ref="form" label-width="80px" inline>
        <el-form-item label="路径备注" prop="remark">
          <el-input v-model="form.remark" placeholder="请输入用户名" :maxlength="100" clearable
            :style="{ width: '100%' }"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button size="small" type="primary" icon="el-icon-search" @click="dataRefresh">查询</el-button>
          <el-button size="small" icon="el-icon-refresh-left" @click="reset">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="toolbar" style="justify-content: flex-end;">
      <!-- <div class="crud">
        <el-button type="primary" @click="Add" size="small">
          <i class="el-icon-plus"></i> 新增
        </el-button>
        <el-button type="primary" @click="Del" size="small">
          <i class="el-icon-delete"></i> 删除
        </el-button>
      </div> -->
      <el-button-group class="right-btn">
        <el-button icon="el-icon-refresh" @click="dataRefresh"></el-button>
        <el-dropdown trigger="click" :hide-on-click="false">
          <el-button icon="el-icon-s-grid">
            <i class="el-icon-caret-bottom"></i>
          </el-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item v-for="(item, index) in TableOptions.column" :key="index">
              <el-checkbox :label="item.label" v-model="item.checked">
              </el-checkbox>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </el-button-group>
    </div>
    <hltable :isNumber="false" :columnEdit="TableOptions.columnEdit" :formSearch="form"
      :refreshFlag="TableOptions.refreshFlag" :tableColumn="TableOptions.column"
      :selectedRows="TableOptions.selectedRows" :apiurl="TableOptions.apiurl" @getSelectedRows="getSelectedRows">
      <!-- <div slot-scope='selected' slot="EditColumn">
        <el-button type="primary" icon="el-icon-edit" size="mini" circle @click="Edit(selected.singlerow)"></el-button>
        <el-button type="warning" size="mini" icon="el-icon-search" circle
          @click="View(selected.singlerow)"></el-button>
      </div> -->
    </hltable>
  </div>
</template>

<script>
import { hltable } from '@/components'
export default {
  name: 'statistics',
  components: { hltable },
  data() {
    return {
      form: {
        remark: ""
      },
      TableOptions: {
        selectedRows: [],
        apiurl: "manager/getDataList",
        refreshFlag: '',
        columnEdit: false,
        column: [{
          label: "路径名称",
          prop: "name",
          checked: true,
        }, {
          label: "点击数",
          prop: "num",
          checked: true,
        }, {
          label: "更新时间",
          prop: "updateTime",
          formatter: function (row) {
            var date = new Date(row.updateTime);
            var year = date.getFullYear(),
              month = ("0" + (date.getMonth() + 1)).slice(-2),
              sdate = ("0" + date.getDate()).slice(-2),
              hour = ("0" + date.getHours()).slice(-2),
              minute = ("0" + date.getMinutes()).slice(-2),
              second = ("0" + date.getSeconds()).slice(-2);
            var result = year + "-" + month + "-" + sdate + " " + hour + ":" + minute + ":" + second;
            return result;
          },
          checked: true,

        }, {
          label: "路径备注",
          prop: "remark",
          checked: true,
        }]
      },
    }
  },
  watch: {
  },
  created() {
  },
  mounted() {

    this.dataRefresh()
  },
  destroyed() {
  },
  methods: {
    dynamicKey() {
      this.$store.dispatch('user/dynamicKey')
    },

    dataRefresh() {
      this.TableOptions.refreshFlag = (new Date()).getTime().toString()
    },

    Add() {
      this.dynamicKey()
      this.setformType("add", "新增")
    },
    Edit(row) {
      this.dynamicKey()
      this.setformType("edit", "编辑")
      this.loadform(row)
    },
    Del: function () {
      if (this.TableOptions.selectedRows.length == 0) {
        this.$message({
          type: 'error',
          message: '请勾选下方表格中的数据'
        })
        return false;
      }
      var ids = []
      this.TableOptions.selectedRows.forEach(key => {
        ids.push(key.id)
      })
      this.$confirm('确定要进行删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$store.dispatch('sysUser/deleteData', { ids: ids })
          .then((response) => {
            if (response.success) {
              this.$message.success("操作成功");
              this.dataRefresh()
            }
            else {
              this.$message.error(response.message);
            }
          })
          .catch((error) => {
            console.log(error)
          })
      })
    },
    View(row) {
      this.setformType("view", "查看")
      this.loadform(row)
    },
    getSelectedRows(rows) {
      this.TableOptions.selectedRows = rows
    },
    reset() {
      // this.$refs['form'].resetFields()
      // 比上方获取demo的清理方式更彻底
      const th = this
      Object.keys(this.form).forEach(function (key) {
        th.form[key] = ""
      })
    },
  }
}
</script>
<style lang="scss" scoped>
@import "@/styles/tabletoptool.scss";
</style>
