<template>
  <div class="export">
    <el-dialog title="渔船抓拍" :visible.sync="dialogVisible" width="50%" center @close="close">
      <div style="text-align:center">
        <template v-for="item in list">
          <el-image style="width: 120px; height: 120px;display:inline-block;margin:5px" :src="item"
            :preview-src-list="list" :key="item">
          </el-image>
        </template>
      </div>
      <h4 v-if="list.length === 0" style="text-align:center">该船只暂无抓拍图像</h4>

    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dialogVisible: false,
      data: {},
      list: [],

    };
  },
  methods: {
    show(data) {
      this.dialogVisible = true;
      this.data = data;
      this.list = this.data.imgPath.split(",");
      this.list = this.list.map(item => {
        return item = "http://58.59.43.176:8031" + item;
      });
    },
    close() {
      this.list = [];
    }
  },
};
</script>
<style lang='less'>
</style>


