<template>
  <div>
    <el-dialog title="视频回放" :visible.sync="dialogVisible" width="50%" @close="handleClose" @opened="openMethods" center>
      <!-- <video-player ref="videoPlayer" class="video-player vjs-custom-skin" :playsinline="true"
        :options="playerOptions" /> -->
      <video controls preload="auto" :playsinline="true" :webkit-playsinline="true" x-webkit-airplay="allow"
        x5-video-player-type="h5-page" x5-video-player-fullscreen="true" style="width:100%">
        <source :src="src" type="video/mp4">
      </video>
    </el-dialog>
  </div>
</template>

<script>

export default {
  data() {
    return {
      dialogVisible: false,
      data: {},
      src: "",
      playerOptions: {
        playbackRates: [0.7, 1.0, 1.5, 2.0], //播放速度
        autoplay: false, // 如果true,浏览器准备好时开始回放。
        muted: false, // 默认情况下将会消除任何音频。
        loop: false, // 导致视频一结束就重新开始。
        preload: "auto", // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: "zh-CN",
        aspectRatio: "16:9", // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [
          {
            type: "video/avi", // 这里的种类支持很多种：基本视频格式、直播、流媒体等，具体可以参看git网址项目
            src:
              "https://cdn.theguardian.tv/webM/2015/07/20/150716YesMen_synd_768k_vp8.webm", // url地址
          },
        ],
        hls: true,
        width: document.documentElement.clientWidth, // 播放器宽度
        notSupportedMessage: "此视频暂无法播放，请稍后再试", // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
        controlBar: {
          timeDivider: true,
          durationDisplay: true,
          remainingTimeDisplay: false,
          fullscreenToggle: true, // 全屏按钮
        },
      },
    };
  },
  methods: {
    show(data) {
      this.dialogVisible = true;
      this.data = data;
      console.log(data);
      this.src = "http://58.59.43.176:8031" + this.data.videoPath;

    },
    openMethods() {
      // this.$refs[`videoPlayer`].player.src("http://58.59.43.176:8031" + this.data.videoPath);
      this.$nextTick(() => {
        this.src = "http://58.59.43.176:8031" + this.data.videoPath;
      })

    },
    handleClose() {
      this.dialogVisible = false;
      // this.$refs.videoPlayer.player.pause(); //暂停
      // this.$refs.videoPlayer.player.src(src); // 重置进度条
    },
  },
};
</script>



