<template>
  <div class="hl-full-container flexVertical">
    <!-- 抽屉表单s -->
    <el-drawer title="新增图片" :visible.sync="dialogFormVisible" direction="rtl" size="100%" :modal="false"
      :destroy-on-close="true" class="el-drawer-hl" @close="handleClose">
      <el-form ref="pform" :model="pictureForm" :disabled="submitType == 'view' ? true : false" size="medium"
        :rules="rules" class="padding15" label-width="120px">
        <el-col :span="12">
          <el-row>
            <el-col :span="24">
              <el-form-item label="图片备注" prop="remark">
                <el-input v-model="pictureForm.remark" placeholder="请输入图片备注" clearable :style="{ width: '100%' }">
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-col>
        <el-col :span="24">
          <el-row>
            <el-col :span="12">
              <el-form-item label="持续时间(秒)" prop="duration">
                <el-input v-model="pictureForm.duration" placeholder="请输入图片持续时间" clearable :style="{ width: '100%' }">
                </el-input>
              </el-form-item>
            </el-col>

          </el-row>
        </el-col>
        <el-col :span="24">
          <el-row>
            <el-col :span="12">
              <el-form-item label="图片排序" prop="sort">
                <el-input v-model="pictureForm.sort" placeholder="请输入图片排序" clearable :style="{ width: '100%' }">
                </el-input>
              </el-form-item>
            </el-col>

          </el-row>
        </el-col>
        <el-col :span="24">
          <el-row>
            <el-col :span="12">
              <el-form-item label="上传图片" prop="file">
                <el-upload action="#" accept=".jpg,.jpeg,.png,.JPG,.JPEG,.PNG" :on-change="fileChange"
                  :auto-upload="false" :file-list="fileList" :limit="1">
                  <el-button size="small" type="primary">请选择上传文件</el-button>

                </el-upload>
              </el-form-item>
            </el-col>

          </el-row>
        </el-col>
        <el-col :span="24">
          <el-row>
            <el-form-item size="large" style="text-align: center;">
              <el-button v-show="submitType != 'view' ? true : false" class="qconfirm" type="primary" size="medium"
                @click="submitForm()">提交</el-button>
              <el-button v-show="submitType != 'view' ? true : false" class="qcancle" size="medium"
                @click="resetForm()">
                重置
              </el-button>
            </el-form-item>
          </el-row>
        </el-col>
      </el-form>
    </el-drawer>


    <!-- 表单e -->
    <div class="search">
      <el-form :model="form" ref="form" label-width="80px" inline>
        <el-form-item label="图片名字" prop="name">
          <el-input v-model="form.name" placeholder="请输入图片名字" :maxlength="100" clearable
            :style="{ width: '100%' }"></el-input>
        </el-form-item>
        <el-form-item label="图片备注" prop="remark">
          <el-input v-model="form.remark" placeholder="请输入图片备注" :maxlength="100" clearable
            :style="{ width: '100%' }"></el-input>
        </el-form-item>

        <el-form-item>
          <el-button size="small" type="primary" icon="el-icon-search" @click="dataRefresh">查询</el-button>
          <el-button size="small" icon="el-icon-refresh-left" @click="reset">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="toolbar" style="justify-content: flex-end;">
      <div class="crud">
        <el-button type="primary" @click="Add" size="small">
          <i class="el-icon-plus"></i> 新增
        </el-button>
        <el-button type="danger" @click="Del" size="small">
          <i class="el-icon-delete"></i> 删除
        </el-button>
      </div>
      <el-button-group class="right-btn">
        <el-button icon="el-icon-refresh" @click="dataRefresh"></el-button>
        <el-dropdown trigger="click" :hide-on-click="false">
          <el-button icon="el-icon-s-grid">
            <i class="el-icon-caret-bottom"></i>
          </el-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item v-for="(item, index) in TableOptions.column" :key="index">
              <el-checkbox :label="item.label" v-model="item.checked">
              </el-checkbox>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </el-button-group>
    </div>
    <hltable :isNumber="false" :columnEdit="TableOptions.columnEdit" :formSearch="form"
      :refreshFlag="TableOptions.refreshFlag" :tableColumn="TableOptions.column"
      :selectedRows="TableOptions.selectedRows" :apiurl="TableOptions.apiurl" @getSelectedRows="getSelectedRows">
      <div slot-scope='selected' slot="EditColumn">
        <el-button type="primary" icon="el-icon-edit" size="mini" circle @click="Edit(selected.singlerow)"></el-button>
        <!-- <el-button type="warning" size="mini" icon="el-icon-search" circle
          @click="View(selected.singlerow)"></el-button> -->
      </div>
    </hltable>
  </div>
</template>

<script>
import { hltable } from '@/components'
export default {
  name: 'statistics',
  components: { hltable },
  data() {
    return {
      form: {
        remark: "",
        name: "",
        opening: ""
      },
      pictureForm: {
        duration: "",
        sort: "",
        remark: ""
      },
      fileList: [],
      formData: new FormData(),
      submitType: '',
      dialogFormVisible: false,
      rules: {
        sort: [{ required: true, message: "此选项必填", trigger: "blur" }],
        duration: [
          { required: true, message: "此选项必填", trigger: "blur" },
        ],

      },
      TableOptions: {
        selectedRows: [],
        apiurl: "manager/getPictureList",
        refreshFlag: '',
        columnEdit: false,
        column: [{
          label: "图片名字",
          prop: "name",
          checked: true,
        }, {
          label: "图片备注",
          prop: "remark",
          checked: true,
        }, {
          label: "持续时间",
          prop: "duration",
          checked: true,
        }, {
          label: "路径",
          prop: "path",
          checked: true,
        }, {
          label: "排序",
          prop: "sort",
          checked: true,
        }]
      },
    }
  },
  watch: {
  },
  created() {
  },
  mounted() {

    this.dataRefresh()
  },
  destroyed() {
  },
  methods: {
    dynamicKey() {
      this.$store.dispatch('user/dynamicKey')
    },

    dataRefresh() {
      this.TableOptions.refreshFlag = (new Date()).getTime().toString()
    },
    fileChange(file, fileList) {
      this.fileList = fileList;
    },
    setformType(type, title) {
      this.dialogFormVisible = true;
      this.dailogTitle = title
      this.submitType = type
    },

    Add() {
      this.dynamicKey()
      this.setformType("add", "新增")
    },
    Edit(row) {
      this.dynamicKey()
      this.setformType("edit", "编辑")
      this.versionForm = { ...row };
      console.log(this.versionForm);

    },

    resetForm() {
      const th = this;
      Object.keys(this.versionForm).forEach(function (key) {
        th.versionForm[key] = ""
      })
    },
    handleClose() {
      this.resetForm();
      this.dialogFormVisible = false;
    },
    submitForm() {
      this.$refs.pform.validate((valid) => {
        if (valid) {
          if (!this.fileList.length) {
            this.$message.error("请先上传图片");
          } else {
            let config = {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            };
            Object.keys(this.pictureForm).forEach((key) => {
              this.formData.append(key, this.pictureForm[key]);
            });
            this.fileList.forEach((file) => {
              this.formData.append("file", file.raw); //此处一定是append file.raw 上传文件只需维护fileList file.raw.name要加上
            });

            this.$store.dispatch('manager/uploadData', this.formData, config)
              .then((response) => {
                if (response.success) {
                  this.$message.success("操作成功");
                  this.dataRefresh();
                }
                else {
                  this.$message.error(response.message);
                }
                this.dialogFormVisible = false
              })
              .catch((error) => {
                this.dialogFormVisible = false
                console.log(error)
              })
          }

        }
      });

    },
    Del: function () {
      if (this.TableOptions.selectedRows.length == 0) {
        this.$message({
          type: 'error',
          message: '请勾选下方表格中的数据'
        })
        return false;
      }
      var ids = []
      this.TableOptions.selectedRows.forEach(key => {
        ids.push(key.id)
      })
      this.$confirm('确定要进行删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$store.dispatch('manager/deleteData', { ids: ids })
          .then((response) => {
            if (response.success) {
              this.$message.success("操作成功");
              this.dataRefresh()
            }
            else {
              this.$message.error(response.message);
            }
          })
          .catch((error) => {
            console.log(error)
          })
      })
    },
    View(row) {
      this.setformType("view", "查看")
      this.loadform(row)
    },
    getSelectedRows(rows) {
      this.TableOptions.selectedRows = rows
    },
    reset() {
      // this.$refs['form'].resetFields()
      // 比上方获取demo的清理方式更彻底
      const th = this
      Object.keys(this.form).forEach(function (key) {
        th.form[key] = ""
      })
    },
  }
}
</script>
<style lang="scss" scoped>
@import "@/styles/tabletoptool.scss";
</style>
